import {Route, Routes} from "react-router-dom";
import CartComponent from "../components/CartComponent";
import MainApp from "../onepirate/MainApp";
import Home from "../onepirate/Home";
import CheckoutProcess from "../components/CheckoutProcess";
import ContactPage from "../onepirate/ContactPage";
import ProductPage from "../components/Products/ProductPage";
import ProductDetail from "../components/Products/ProductDetail";
import WhyMetalPrintPage from "../components/WhyMetalPrintPage";


const AppRouter = () => {
    return (
        <Routes>
            {/*<Route key="route-root" path="/" element={<HomePage />}>*/}
            <Route key="route-root" path="/" element={<MainApp />}>
                <Route index element={<Home />}/>
                <Route key="route-cart" path="/cartOld" element={<CartComponent />} />
                <Route key="route-cart" path="/metalicky-obraz" element={<WhyMetalPrintPage />} />
                <Route key="route-cart" path="/cart" element={<CheckoutProcess />} />
                <Route key="route-cart" path="/contact" element={<ContactPage />} />
                <Route key="route-cart" path="/products" element={<ProductPage />} />
                <Route
                    key="route-product-detail"
                    path="/product/:productId" // Dynamická cesta pro detail produktu
                    element={<ProductDetail />} // Komponenta detailu produktu
                />
                {/*<Route path="catalog" element={<ProductCatalogPage />}/>*/}
                {/*<Route path="product/:productId/:name?" element={<ProductDetail />} />*/}
            </Route>

        </Routes>
    );
}

export default AppRouter;