// Typ pro kupóny
interface Coupon {
    code: string;
    discount: number; // Sleva ve formě desetinného čísla (např. 0.1 pro 10%)
}

// Definice kupónů v poli
const coupons: Coupon[] = [
    { code: "KING", discount: 0.25 }, // 10% sleva
];

// Funkce pro kontrolu platnosti slevového kódu
export const checkDiscount = (code: string): Coupon | null => {
    const validCoupon = coupons.find((coupon) => coupon.code === code.toUpperCase());
    return validCoupon || null; // Vrátí kupón, pokud je kód platný, jinak null
};

export const getCouponDiscount = (couponCode: string | null): number => {
    if (!couponCode) return 0; // Bez kupónu není sleva

    // Najde kupon ve výchozím seznamu kupónů
    const validCoupon: Coupon | undefined = coupons.find(coupon => coupon.code === couponCode.toUpperCase());

    // Pokud je kupón platný, vrátí jeho slevu, jinak vrátí 0
    return validCoupon ? validCoupon.discount : 0;
};