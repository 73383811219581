import React, {useRef, useState} from "react";
import Slider from '@mui/material/Slider';
import Cropper from "react-easy-crop";
import {Point, Area} from "react-easy-crop/types";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "../../onepirate/modules/components/Typography";
import Output from "./Output";
import Button from "../../onepirate/modules/components/Button";
import {MuiFileInput} from "mui-file-input";
import {MediaItemReq} from "./MediaItemReq";
import {FormControl, Grid2, InputLabel, OutlinedInput, Paper, Select, Stack, useTheme} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {styled, Theme} from "@mui/material/styles";
import PlateSize from "../PlateSize";
import {MountType, Product, Size} from "../PlateSizeType";
import InsertToCartDialog from "../InsertToCartDialog";
import {useRecoilState} from "recoil";
import {cartState} from "../../recoil/atoms";
import {CartItem} from "../../types/CartItem";
import CartComponent from "../CartComponent";
import {useNavigate} from "react-router-dom";
import {getCroppedImg, readFileAsBase64} from "./canvasUtils";
import {ImageRequest, useCreateOrder, useUploadImage} from "../../api/order_api";
import Grid from "@mui/material/Grid2";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {SelectChangeEvent} from "@mui/material/Select";
import {sendGtagEvent, sendToDatalayer} from "../../utils/google";

function readFile(file: File) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}


function calcAspect(vertical: Boolean, PlateSizeType: Size | null) {
    let aspectRatio = 1;
    if (vertical) {
        aspectRatio = PlateSizeType ? PlateSizeType.width / PlateSizeType.height : 1;
    } else {
        aspectRatio = PlateSizeType ? PlateSizeType.height / PlateSizeType.width : 1;
    }
    return aspectRatio;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

interface ImageCropperProps {
    selectedProduct?: Product | null;
    fileInputRef?: React.RefObject<HTMLInputElement>;
}

const ImageCropper: React.FC<ImageCropperProps> = ({ selectedProduct, fileInputRef }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [crop, setCrop] = useState<Point>({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [croppedArea, setCroppedArea] = useState<Area | null>(null);

    console.log("selectedProduct", selectedProduct)
    // Pokud není `fileInputRef` poskytnut, vytvoří se lokální ref
    const internalFileInputRef = useRef<HTMLInputElement | null>(null);
    const inputRef = fileInputRef || internalFileInputRef;

    // FILE
    const [file, setFile] = useState<File | null>(null);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

    //nastaveni
    const [product, setProduct] = React.useState<Product | null>(selectedProduct ?? null);
    const [vertical, setVertical] = React.useState(false);

    const uploadImageMutation = useUploadImage();

    // vyber stojanku
    const [selectedMount, setSelectedMount] = React.useState<MountType | null>(null);


    const handleChange = async (newFile: any) => {
        console.log("FILE", newFile);

        if (newFile) {
            let imageDataUrl = await readFile(newFile); // Předpokládáme, že readFile vrací string nebo něco podobného


            // Předpokládáme, že imageDataUrl je string zde
            if (typeof imageDataUrl === 'string') {
                setImageSrc(imageDataUrl as string); // Předpokládáme, že setImageSrc očekává typ string
            } else {
                // Zpracování chyby nebo alternativní cesta, pokud imageDataUrl není string
                console.error("Invalid image data URL");
            }
        } else {
            // Pokud newFile není definován, nastavíme imageSrc na undefined
            setImageSrc(undefined);
        }

        setFile(newFile);
    };

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
        console.log(croppedArea, croppedAreaPixels);
    };

    const onChangeSize = (size: Product | null) => {
        console.log("onChangeSize", size);
        setProduct(size);
    }


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    };

    const onChangeVertical = (vertical: boolean) => {
        setVertical(vertical);
    }


    // dialog vlozeni do kosiku
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleCreateAnother = () => {
        // Logika pro vytvoření dalšího obrazu
        console.log('Creating another image...');
        setCrop({x: 0, y: 0});
        setZoom(1);
        setCroppedAreaPixels(null);
        setCroppedArea(null);
        setFile(null);
        setImageSrc(undefined);
        setVertical(false);
        setProduct(null);
        handleCloseDialog();
        const orderSection = document.getElementById('order');
        if (orderSection) {
            orderSection.scrollIntoView({behavior: 'smooth'});
        }
    };

    const handleViewCart = () => {
        // Logika pro zobrazení košíku
        handleCloseDialog();
        window.scrollTo(0, 0);
        navigate('/cart');
    };

    const [cart, setCart] = useRecoilState(cartState);

    function getRandomId(): number {
        return Math.floor(Math.random() * 10000); // Generuje náhodné číslo mezi 0 a 999
    }


    const addToCart = async () => {
        if (!product) return;
        let orderSize: Product = product;

        let mount: MountType | null = null;
        if (selectedMount) mount=selectedMount;

        if (vertical) {
            orderSize = {id: product.id, name: product.name, size: {width: product.size.height, height: product.size.width}, thick: product.thick, roundedCorner: product.roundedCorner, price: product.price};
        }
        const productID = getRandomId();
        const orderProduct: CartItem = {itemTimestamp: new Date(), id: productID, price: product.price, name: orderSize.name, size: orderSize.size, thick: orderSize.thick, roundedCorner: orderSize.roundedCorner, vertical: vertical, quantity: 1, mount: mount};
        console.log("mount, selectedmount, orderproduct", mount, selectedMount, orderProduct)

        // Oříznutí obrázku
        if (imageSrc && croppedAreaPixels !== null) {
            console.log("Saving cropped image...", imageSrc, croppedAreaPixels, vertical);
            try {
                const croppedImageUrl = await getCroppedImg(imageSrc, croppedAreaPixels, 0, {horizontal: false, vertical: false});

                if (croppedImageUrl) {

                    const imageCroped: ImageRequest = {
                        id: "IMG_" + productID.toString() + "_" + orderSize.size.height + "x" + orderSize.size.width + "_croped",
                        base64Image: croppedImageUrl,
                    }

                    // odeslani oriznuteho img
                    uploadImageMutation.mutate(imageCroped, {
                        onSuccess: () => {
                            console.log("Odeslano")
                        },
                        onError: (error: Error) => {
                            console.error("Chyba při odesílání objednávky:", error);
                            return;
                        }
                    });
                }
                // original
                if (file) {
                    const originalImageUrl = await readFileAsBase64(file);
                    const imageOriginal: ImageRequest = {
                        id: "IMG_" + productID.toString() + "_" + orderSize.size.width + "x" + orderSize.size.height + "_original",
                        base64Image: originalImageUrl,
                    }

                    // odeslani original
                    uploadImageMutation.mutate(imageOriginal, {
                        onSuccess: () => {
                            console.log("Odeslano")
                        },
                        onError: (error: Error) => {
                            console.error("Chyba při odesílání objednávky:", error);
                            return;
                        }
                    });

                }
                // sendToDatalayer('printking-add-cart');
                sendGtagEvent('event', 'conversion', {'send_to': 'AW-1115942164/Vd7YCN7pg-8ZEMuFnckp'});
                /*
                    <!-- Event snippet for Printking.cz - přidani do košíku conversion page -->
    <script>
      gtag('event', 'conversion', {'send_to': 'AW-AW-1115942164/Vd7YCN7pg-8ZEMuFnckp'});
    </script>

                 */
                console.log("Cropped image URL:", croppedImageUrl);
            } catch (error) {
                console.error("Failed to crop image:", error);
                return;
            }
        }
        setCart([...cart, {...orderProduct, quantity: 1}]);
        handleOpenDialog();
    };


    const handleChangeSelect = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        const newMount = value ? JSON.parse(value) : null;
        setSelectedMount(newMount);
        console.log("newMount",newMount)
    };

    const formatValue = (mount: MountType | null) => {
        return mount ? JSON.stringify(mount) : '';
    };

    return (
        <Container
            sx={{
                mt: 3,
                mb: 1,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Grid container spacing={2} sx={{justifyContent: 'center', alignItems: 'center', mt: 1}} width={"100%"}>
                <Grid size={12} sx={{justifyContent: 'center', alignItems: 'center'}}>
                    <MuiFileInput
                        inputRef={inputRef}
                        placeholder="Vložit obrázek"
                        title={"Nahrajte svou fotografii a vytvořte jedinečný kovový obraz na zeď."}
                        value={file}
                        onChange={handleChange}
                        size="small"
                        variant="outlined"
                        inputProps={{accept: ['.jpg', '.jpeg', '.png']}}
                        //sx={{ width: '80%', maxWidth: '400px' }}
                        fullWidth
                        label={file ? "Soubor" : ""}
                    />
                </Grid>

                {imageSrc && (
                    <>
                        {!selectedProduct && (
                            <Grid size={{xs: 12, sm: 4}} sx={{justifyContent: 'center', alignItems: 'center'}}>
                                <PlateSize onChange={onChangeSize}/>
                            </Grid>
                        )}
                        <Grid size={{xs: 12, sm: 4}} sx={{justifyContent: 'center', alignItems: 'center'}}>
                            <Button
                                variant={vertical ? "outlined" : "contained"}
                                size="small"
                                onClick={() => onChangeVertical(false)}
                                fullWidth
                                title="Zvolte horizontální orientaci obrazu"
                                aria-label="Horizontální orientace"
                            >
                                Horizontální
                            </Button>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4}} sx={{justifyContent: 'center', alignItems: 'center'}}>
                            <Button
                                variant={vertical ? "contained" : "outlined"}
                                size="small"
                                onClick={() => onChangeVertical(true)}
                                fullWidth
                                title="Zvolte vertikální orientaci obrazu"
                                aria-label="Vertikální orientace"
                            >
                                Vertikální
                            </Button>
                        </Grid>
                    </>
                )}

            </Grid>

            {imageSrc && product !== null && (
                <Grid container spacing={2} sx={{justifyContent: 'center', alignItems: 'center', mt: 1}} width={"100%"}>
                    <Grid size={{xs: 12}} sx={{justifyContent: 'center', alignItems: 'center'}}>
                        <Box sx={{position: 'relative', mt: 1, width: '100%', height: '300px'}}>
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={calcAspect(vertical, product.size)}
                                //restrictPosition
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                onCropAreaChange={setCroppedArea}
                                zoomWithScroll={false}
                                aria-label="Editor výřezu. Použijte posuvníky pro úpravu výřezu a přiblížení."
                            />
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ alignItems: 'center', mt: 1 }}>
                        <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
                            <ZoomOutIcon />
                            <Slider
                                aria-label="Zoom"
                                title="Zvětšení obrazu"
                                aria-valuetext={`Aktuální zoom: ${zoom.toFixed(2)}`}
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.01}
                                onChange={(e, zoom) => setZoom(Number(zoom))}
                                sx={{ width: '100%' }}
                            />
                            <ZoomInIcon />
                        </Stack>
                    </Grid>


                </Grid>

            )}

            {imageSrc && product !== null && croppedArea && (
                <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', mt: 1 }} width={"100%"}>
                    <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Output
                            image={imageSrc}
                            croppedArea={croppedArea}
                            cropAreaAspect={calcAspect(vertical, product.size)}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} width={"100%"}>
                        <Item sx={{ padding: 4}}>
                            <Grid container spacing={0} sx={{ mt: 1, mb: 2 }} width={"100%"}>
                                <Grid container size={12} sx={{ alignItems: 'center', mb: 1 }}>
                                    <Grid size="auto" sx={{ textAlign: 'left' }}>
                                        <Typography variant="h6">Velikost:</Typography>
                                    </Grid>
                                    <Grid sx={{ flex: 1, textAlign: 'right' }}>
                                        <Typography variant="h6" fontWeight="bold">{product?.size.width} x {product?.size.height} mm</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container size={12} sx={{ alignItems: 'center' }}>
                                    <Grid size="auto" sx={{ textAlign: 'left' }}>
                                        <Typography variant="h6">Cena:</Typography>
                                    </Grid>
                                    <Grid sx={{ flex: 1, textAlign: 'right' }}>
                                        <Typography variant="h6" fontWeight="bold">{product?.price} Kč</Typography>
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth sx={{ mt: 1, mb: 1, minWidth: 240 }} size="small">
                                    <InputLabel id="select-label">Způsob uchycení</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        id="select"
                                        size="small"
                                        value={formatValue(selectedMount)}
                                        label="Způsob uchycení"
                                        title={"Zvolte způsob uchycení pro kovový obraz."}
                                        onChange={handleChangeSelect}
                                        sx={{
                                            textAlign: "left", // Zajišťuje zarovnání textu vlevo
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    textAlign: "left", // Zajišťuje zarovnání v menu
                                                },
                                            },
                                        }}
                                    >
                                        {product.mountType?.map((mount) => (
                                            <MenuItem
                                                key={mount.id}
                                                value={formatValue(mount)}
                                                title={mount.title}
                                                aria-label={`${mount.name} - ${mount.description}`}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start", // Zajišťuje zarovnání vlevo v jednotlivých položkách
                                                    width: "100%"
                                                }}
                                            >
                                                <Grid container spacing={1} alignItems="center" sx={{ width: "100%" }}>
                                                    <Grid size={{ xs: 8 }}>
                                                        <Typography variant="body1">{mount.name}</Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {mount.description}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        size={{ xs: 4 }}
                                                        sx={{
                                                            textAlign: "right",
                                                            display: "flex",
                                                            justifyContent: "flex-end", // Zajišťuje zarovnání obsahu v Gridu doprava
                                                            alignItems: "center", // Vertikální zarovnání obsahu
                                                        }}
                                                    >
                                                        <Typography variant="body1" fontWeight="bold">
                                                            {mount.price === 0 ? "ZDARMA" : `+ ${mount.price} Kč`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Typography variant={"h4"}>
                                Celkem: {selectedMount && selectedMount.price !== undefined ? selectedMount.price + product.price : product.price} Kč
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{
                                    marginTop: '18px',
                                    //textTransform: 'none',
                                    fontWeight: 'bold',
                                }}
                                onClick={addToCart}
                                disabled={product === null || product === undefined || !selectedMount}
                                title={"Přidat obraz do košíku"}
                            >
                                Vložit do košíku
                            </Button>
                        </Item>
                    </Grid>
                </Grid>
            )}
            <InsertToCartDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onCreateAnother={handleCreateAnother}
                onViewCart={handleViewCart}
            />
        </Container>

    );
};

export default ImageCropper;