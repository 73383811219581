import { atom } from 'recoil';
import {CartItem, CustomerInfo, ShippingPaymentInfo} from "../types/CartItem";
import {recoilPersist} from "recoil-persist";

const { persistAtom } = recoilPersist()

export const cartState = atom<CartItem[]>({
  key: 'CartState',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export interface CheckoutState {
  cartTimestamp?: Date;
  cartItems: CartItem[];
  shippingPaymentInfo: ShippingPaymentInfo;
  customerInfo: CustomerInfo;
  message: string;
  couponCode: string | null;
}

export const checkoutState = atom<CheckoutState>({
  key: 'CheckoutState',
  default: {
    cartTimestamp: undefined,
    cartItems: [],
    shippingPaymentInfo: {
      shippingMethod: null,
      paymentMethod: null
    },
    customerInfo: {
      firstName: '',
      lastName: '',
      address: {
        street: '',
        city: '',
        state: 'Česká republika',
        zipCode: ''
      },
      email: '',
      phone: ''
    },
    message: '',
    couponCode: null
  },
  effects_UNSTABLE: [persistAtom],
});