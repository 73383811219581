import React, { useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Typography from "../onepirate/modules/components/Typography";

const imageCount = 4; // Počet obrázků
// const images = Array.from({ length: imageCount }, (_, i) => ({
//     src: `https://via.placeholder.com/600x400?text=Obraz+${i + 1}`, // Nahraď reálnými URL obrázků
//     alt: `Obraz ${i + 1}`,
// }));
const images = Array.from({ length: imageCount }, (_, i) => ({
    src: `/static/images/${i + 1}.jpg`, // Nahraď reálnými URL obrázků
    alt: `Obraz ${i + 1}`,
}));

const Gallery: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    return (
        <Box sx={{ textAlign: "center", marginBottom: 4, paddingTop: 18 }}>
            <Typography variant="h4"  marked="center" component="h2" sx={{ mb: 6 }}>
                Podívejte se, jak vypadají naše obrazy na hliníkovém plechu!
            </Typography>

            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" }, // Sloupec na mobilu, řádek na desktopu
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {images.map((image, i) => (
                    <Grid key={i} size={{ xs: 12, sm: 6, md: 2 }}>
                        <Box
                            onClick={() => {
                                setIndex(i);
                                setOpen(true);
                            }}
                            sx={{
                                width: "100%",
                                height: 200,
                                // backgroundColor: "grey.300",
                                backgroundImage: `url(${image.src})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: '8px',
                                cursor: "pointer",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.1)",
                                },
                            }}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Lightbox pro zvětšení obrázků */}
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={images}
                index={index}
            />
        </Box>
    );
};

export default Gallery;
