import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

interface InfoBarProps {
    endDateTime?: string | Date | undefined;
    message: string | React.ReactNode;
    backgroundColor?: string; // Barva pozadí (volitelné)
    textColor?: string;       // Barva textu (volitelné)
}

const isValidDateTimeString = (dateTime: string): boolean => {
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/; // Formát YYYY-MM-DD hh:mm
    return regex.test(dateTime);
};

const isOpenState = (endDateTime: string | Date): boolean => {
    let endDate: Date;

    if (typeof endDateTime === 'string') {
        const [datePart, timePart] = endDateTime.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes] = timePart.split(':').map(Number);

        endDate = new Date(year, month - 1, day, hours, minutes);
    } else {
        endDate = endDateTime;
    }

    return new Date().getTime() < endDate.getTime();
};

const InfoBar: React.FC<InfoBarProps> = ({ endDateTime, message, backgroundColor, textColor }) => {
    const [timeLeft, setTimeLeft] = useState<string>('');
    const isVisible = endDateTime === undefined ? true : isOpenState(endDateTime);

    useEffect(() => {
        if (!isVisible || !endDateTime) return;

        let endDate: Date;

        if (typeof endDateTime === 'string') {
            const [datePart, timePart] = endDateTime.split(' ');
            const [year, month, day] = datePart.split('-').map(Number);
            const [hours, minutes] = timePart.split(':').map(Number);

            endDate = new Date(year, month - 1, day, hours, minutes);
        } else {
            endDate = endDateTime;
        }

        const updateCountdown = () => {
            const now = new Date();
            const difference = endDate.getTime() - now.getTime();

            if (difference <= 0) {
                setTimeLeft('');
                return;
            }

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft(
                `${days.toString().padStart(2, '0')} ` +
                `${days === 1 ? 'den' : (days >= 2 && days <= 4 ? 'dny' : 'dnů')} ` +
                `${hours.toString().padStart(2, '0')}:` +
                `${minutes.toString().padStart(2, '0')}:` +
                `${seconds.toString().padStart(2, '0')}`
            );
        };

        // Spustit odpočet ihned
        updateCountdown();

        // Nastavit interval pro aktualizaci každou sekundu
        const interval = setInterval(updateCountdown, 1000);

        // Vyčistit interval při odpojení komponenty
        return () => clearInterval(interval);
    }, [endDateTime, isVisible]);

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: backgroundColor || 'secondary.main',
                color: textColor || 'secondary.contrastText',
                py: 1,
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
            }}
        >
            <Container maxWidth="lg">
                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                    }}
                >
                    {message}
                    {timeLeft && (
                        <>
                            <br />
                            {`Zbývá: ${timeLeft}`}
                        </>
                    )}
                </Typography>
            </Container>
        </Box>
    );
};

export default InfoBar;