import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

// const backgroundImage =
//   '';

const backgroundImage =
    '/static/photo-1534854638093-bada1813ca19.webp';

export default function ProductHero() {

    const onClickButton = () => {
        console.log("Button clicked");
        // sendGtagEvent('event', 'conversion', {
        //   'send_to': 'AW-16790889443/L7gbCLvN0O0ZEOPHwsY-'
        // });


        // if (Array.isArray(window.dataLayer)) {
        //   console.log("Sending to dataLayer");
        //   window.dataLayer.push({
        //     event: 'clickButton',
        //     label: 'Test Button',
        //   });
        // } else {
        //   console.warn('dataLayer is not defined');
        // }
    };

    // <!-- Event snippet for Nákup conversion page -->
    // <script>
    //   gtag('event', 'conversion', {
    //   'send_to': 'AW-16790889443/klo8CLHc3-0ZEOPHwsY-',
    //   'value': 1.0,
    //   'currency': 'CZK',
    //   'transaction_id': ''
    // });
    // </script>


    /*

    <!-- Event snippet for Test tlačítka conversion page
      In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
      <script>
      function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-16790889443/L7gbCLvN0O0ZEOPHwsY-',
            'event_callback': callback
        });
        return false;
      }
      </script>


     */


    return (
        <ProductHeroLayout
            sxBackground={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#7fc7d9', // Average color of the background image.
                backgroundPosition: 'center',
            }}
        >
            {/* Increase the network loading priority of the background image. */}
            <img
                style={{display: 'none'}}
                src={backgroundImage}
                alt="increase priority"
                loading={"lazy"}
            />
            <Typography
                color="inherit"
                align="center"
                variant="h2"
                marked="center"
            >
                Proměňte své vzpomínky v kovové umění!
            </Typography>

            <Typography
                color="inherit"
                align="center"
                variant="h5"
                sx={{mb: 4, mt: {xs: 4, sm: 10}}}
                component="h2"
            >
                {/*Proměňte své oblíbené vzpomínky v nadčasové kovové umění. Vyberte svou fotku, upravte detaily a my ji přeneseme na odolný hliníkový plech, který rozzáří každý prostor.      </Typography>*/}
                Vyberte svou oblíbenou fotografii, přizpůsobte detaily a my ji přeneseme na hliníkový plech. Vytvoříme pro vás jedinečný metalický obraz, který
                ozdobí váš domov nebo potěší jako originální dárek.
            </Typography>
            <Button
                color="primary"
                variant="contained"
                size="large"
                component="a"
                href="#order"
                sx={{minWidth: 200}}
                title={"Vytvořte si vlastní metalický obraz z fotografie."}
            >
                Vytvořit obraz
            </Button>
            {/*<Typography variant="body2" color="inherit" sx={{ mt: 2 }}>*/}
            {/*  nyní s 20% slevou*/}
            {/*</Typography>*/}
        </ProductHeroLayout>
    );
}
