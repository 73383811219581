import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const StyledLink = styled(RouterLink)(({ theme }) => ({
    display: 'inline-block',
    textDecoration: 'none',
    textAlign: 'center',
    textTransform: 'uppercase',
    borderRadius: 0,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.h1.fontFamily,
    padding: theme.spacing(2, 4),
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    boxShadow: 'none',
    transition: 'background-color 0.3s ease, color 0.3s ease', // Přidání plynulého přechodu
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
    '&:active, &:focus': {
        boxShadow: 'none',
    },
    variants: [
        {
            props: {
                size: 'small',
            },
            style: {
                padding: theme.spacing(1, 3),
                fontSize: theme.typography.pxToRem(13),
            },
        },
        {
            props: {
                size: 'large',
            },
            style: {
                padding: theme.spacing(2, 5),
                fontSize: theme.typography.pxToRem(16),
            },
        },
    ],
}));

interface LinkButtonProps extends RouterLinkProps {
    size?: 'small' | 'large';
    onClick?: () => void;
}

function LinkButton({ onClick, ...props }: LinkButtonProps) {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
            onClick(); // Spuštění vlastní funkce, pokud je definována
        }
        window.scrollTo(0, 0); // Provede posun na začátek stránky
    };

    return <StyledLink {...props} onClick={handleClick} />;
}

export default LinkButton;
