import React from 'react';
import Box from '@mui/material/Box';
import Typography from "../onepirate/modules/components/Typography";
import Gallery from "./Gallery";


interface ImageData {
    src: string;
    alt: string;
}

const images: ImageData[] = [
    {
        src: '/static/inspiration/family_moments.jpg',
        alt: 'Kovový obraz na zeď',
    },
    {
        src: '/static/inspiration/art.png',
        alt: 'Luxusní metalický obraz',
    },
    {
        src: '/static/inspiration/portret.jpg',
        alt: 'Super lesklý moderní obraz',
    },
];

const MetalImageGallery: React.FC = () => {
    const imageStyles = [
        {
            transform: 'perspective(50em) rotateX(12deg)  rotate(-15deg)',
        },
        {
            transform: 'perspective(50em) rotateX(12deg) rotate(0deg)',
        },
        {
            transform: 'perspective(50em) rotateX(12deg) rotate(15deg)',
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: '80px auto',
                position: 'relative',
                zIndex: 1, // Ujistíme se, že není nad footerem
            }}
        >
            {/* Nadpisy */}
            <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
                <Typography
                    variant="h2"
                    marked={"center"}
                    component="h2"
                    sx={{
                        fontSize: '2rem',
                        lineHeight: 1.5,
                        color: '#333',
                        fontWeight: 'bold',
                    }}
                >
                    Inspirace pro vás
                </Typography>
                <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                        fontSize: '1.2rem',
                        color: '#606060',
                        marginTop: 1,
                    }}
                >
                    Metalický obraz vyniká moderním vzhledem a dlouhou životností
                </Typography>
            </Box>

            {/* Obrázky */}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 4,
                    position: 'relative',
                    width: '100%',
                    maxWidth: '1200px', // Zamezíme přetékání na velkých obrazovkách
                }}
            >
                {images.map((image, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: ['90%', '45%', '30%'], // Responzivní šířky pro různé velikosti obrazovek
                            position: 'relative',
                        }}
                    >
                        <Box
                            component="img"
                            src={image.src}
                            alt={image.alt}
                            sx={{
                                width: '100%',
                                border: '1px solid #68686887',
                                borderRadius: '0.5em',
                                boxShadow: '0px 1px 0 0px #a0a0a0, 0 0 30px 10px #0000003d',
                                transform: imageStyles[index].transform,
                            }}
                        />
                    </Box>
                ))}
            </Box>

            <Gallery />
        </Box>
    );
};

export default MetalImageGallery;
