import {CheckoutState} from "../../recoil/atoms";
import {Customer, DeliveryMethod, Order, OrderItem, PaymentMethod, Address} from "../interface/OrderInterface";

export const mapCheckoutStateToOrder = (checkoutState: CheckoutState): Order => {
    const deliveryPrice = checkoutState.shippingPaymentInfo.shippingMethod?.price ?? 0;
    const paymentPrice = checkoutState.shippingPaymentInfo.paymentMethod?.price ?? 0;
    const items: OrderItem[] = checkoutState.cartItems.map(item => ({
        product: {
            productId: item.id,
            productName: `${item.name} -  ${item.size.height} x ${item.size.width}`,
            description: `size ŠxV: ${item.size.height} x ${item.size.width}, thick: ${item.thick}, vertical: ${item.vertical}, mount: ${item.mount?.name}`,
            thick: item.thick,
            roundedCorner: !!item.roundedCorner,
            mount: item.mount,
            price: item.mount?.price !== undefined ?  item.mount?.price + item.price : item.price,
        },
        quantity: item.quantity,
        totalPrice: item.mount?.price !== undefined ? (item.mount?.price + item.price) * item.quantity : item.price + item.quantity,
    }));

    const price = items.reduce((total, item) => total + (item.product.price * item.quantity), 0);
    const totalPrice = price + deliveryPrice + paymentPrice;

    const deliveryMethod: DeliveryMethod = {
        name: checkoutState.shippingPaymentInfo.shippingMethod?.name || '',
        price: checkoutState.shippingPaymentInfo.shippingMethod?.price || 0,
        description: checkoutState.shippingPaymentInfo.shippingMethod?.place
    };

    const paymentMethod: PaymentMethod = {
        name: checkoutState.shippingPaymentInfo.paymentMethod?.name || '',
        price: checkoutState.shippingPaymentInfo.paymentMethod?.price || 0
    };

    const address: Address = {
        addressLine1: checkoutState.customerInfo.address.street,
        city: checkoutState.customerInfo.address.city,
        country: checkoutState.customerInfo.address.state,
        zip: checkoutState.customerInfo.address.zipCode
    };

    const customer: Customer = {
        firstname: `${checkoutState.customerInfo.firstName}`,
        lastname: `${checkoutState.customerInfo.lastName}`,
        address: address,  // Adjusted to map properly
        email: checkoutState.customerInfo.email,
        telephone: checkoutState.customerInfo.phone
    };

    return {
        customer,
        totalPrice,
        price,
        deliveryPrice,
        paymentPrice,
        orderStatus: 'NEW', // Example value, update as necessary
        deliveryMethod,
        paymentMethod,
        items,
        message: checkoutState.message,
        couponCode: checkoutState.couponCode
    };
};