import React, {ReactNode, useRef} from 'react';
import {Box, Typography, Container, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid2'; // Import Grid2
import {useParams, useNavigate} from 'react-router-dom';
import {formatSizeCM, products} from '../../resources/sizesConstants';
import ImageCropper from "../ImageCropper/ImageCropper";
import Button from "../../onepirate/modules/components/Button";
import MetalImageGallery from "../MetalImageGallery";
import TechnicalDetails from "./TechnicalDetails";
import {sendGtagEvent} from "../../utils/google";
import {Helmet} from "react-helmet-async";
import {Product} from "../PlateSizeType";

interface ProductDetailAccordionText {
    heading: string;
    description: ReactNode;
}


const accordionTexts: ProductDetailAccordionText[] = [
    {
        heading: "Proč metalický obraz?",
        description: (
            <>
                <Typography variant="body2"
                            color="text.secondary"
                            sx={{
                                fontSize: '1rem',
                                lineHeight: 1.7
                            }}
                >
                    Hliníkové obrazy jsou nejen stylové, ale také odolné. Na rozdíl od běžných pláten nabízejí:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        marginBottom: 3,
                                        fontSize: '1rem',
                                        lineHeight: 1.7
                                    }}
                        >
                            <strong>Dokonalý lesk:</strong> Speciální povrch obrazu dokonale zachycuje i ty nejjemnější detaily, díky čemuž vaše fotografie ožijí v celé své kráse. Unikátní vizuální efekt zdůrazňuje barvy a přidává hloubku každému snímku, čímž povyšuje vaši tvorbu na úroveň skutečného uměleckého díla. Tento výjimečný lesk je ideální volbou pro moderní interiéry, kde se snoubí elegance s promyšleným designem.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        marginBottom: 3,
                                        fontSize: '1rem',
                                        lineHeight: 1.7
                                    }}
                        >
                            <strong>Trvanlivost:</strong> Na rozdíl od tradičních pláten vynikají hliníkové obrazy svou mimořádnou odolností, která garantuje jejich dlouhou životnost. Jsou navrženy tak, aby odolaly i náročným podmínkám, jako je vysoká vlhkost, přímé sluneční záření nebo teplotní výkyvy. Váš obraz si zachová svůj jedinečný vzhled a působivou barevnost po mnoho let, a to bez potřeby jakékoli speciální péče či údržby.
                        </Typography>
                    </li>
                    {/*<li>*/}
                    {/*    <Typography variant="body2" color="text.secondary">*/}
                    {/*        <strong>Ekologii:</strong> Hliníkové obrazy jsou vyráběny z materiálů, které lze plně recyklovat, což z nich činí udržitelnou volbu pro moderní domácnosti. Při výrobě je kladen důraz na minimální ekologickou stopu, a tak kombinují estetiku s odpovědným přístupem k přírodě. Kromě toho jejich dlouhá životnost znamená, že není nutné je často nahrazovat, což přispívá k ochraně životního prostředí.*/}
                    {/*    </Typography>*/}
                    {/*</li>*/}
                </ul>
                <Typography variant="body2"
                            color="text.secondary"
                            sx={{
                                fontSize: '1rem',
                                lineHeight: 1.7
                            }}
                >
                    Objevte způsob, jak zdůraznit své fotografie a vytvořit z nich trvalou ozdobu vašeho interiéru.
                </Typography>
            </>
        ),
    },
    {
        heading: "Snadné zavěšení a umístění",
        description: (
            <Grid container spacing={4} sx={{ alignItems: "flex-start" }}>
                {/* Levý sloupec s textem */}
                <Grid size={{ xs: 12, md: 6 }}>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            marginBottom: 3,
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Naše obrazy jsou navrženy s důrazem na jednoduchost a flexibilitu instalace. Můžete si zvolit dřevěný stojánek, který umožňuje snadné vystavení na stole či poličce, nebo „neviditelný“ rám umístěný na zadní straně obrazu.                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            marginBottom: 3,
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Pokud zvolíte variantu s neviditelným rámem, obdržíte v balení i praktický držák, který lze snadno přišroubovat na libovolné místo na stěně.
                        Nově však ke každému rámečku přidáváme také <strong>držák s lepicí páskou</strong>, který umožňuje připevnění obrazu na stěnu bez nutnosti vrtání.
                        Díky těmto možnostem je zavěšení či umístění našich obrazů rychlé, bezpečné a přizpůsobí se vašim potřebám.
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Objevte způsob, jak své obrazy vystavit jednoduše a s dokonalým vzhledem!
                    </Typography>
                </Grid>

                {/* Pravý sloupec s obrázky */}
                <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            //flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 2
                        }}
                    >
                        {["/static/product/neviditelny_ramecek.png", "/static/product/dreveny_stojanek_bez_foto.png"].map((src, index) => (
                            <Box
                                key={index}
                                sx={{
                                    position: 'relative',
                                    width: '50%',
                                    //aspectRatio: '4 / 3', // Nastavení poměru stran (např. 4:3)
                                    overflow: 'hidden',
                                    borderRadius: '12px',
                                    '&:hover img': {
                                        transform: 'scale(1.5)'
                                    }
                                }}
                            >
                                <img
                                    src={src}
                                    alt={`Thumbnail ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        transition: 'transform 0.3s ease'
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        ),
    },
    {
        heading: "Technické detaily",
        description: (
            <>
                <TechnicalDetails />
            </>

        ),
    },
    {
        heading: "Dodací lhůta",
        description: (
            <Typography variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
            >
                Vaši objednávku se snažíme zpracovat co nejrychleji. Obvyklá doba vyhotovení je do 24 hodin od objednávky. Následně zboží pečlivě zabalíme a ještě tentýž den expedujeme. Díky tomu se můžete na svou objednávku těšit v co nejkratším možném čase!
            </Typography>
        ),
    },
];


const ProductDetail: React.FC = () => {
    const {productId} = useParams<{ productId: string }>();
    const [create, setCreate] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    // Najdeme produkt podle ID z URL
    const product : Product | undefined = products.find((p) => p.id === parseInt(productId || '', 10));

    const onClickCreate = () => {
        setCreate(true);
        sendGtagEvent('event',  'conversion', {
            'send_to': 'AW-16790889443/4rGtCIWEm_UZEOPHwsY-',
        });

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };



    if (!product) {
        return (
            <Container sx={{paddingTop: 5, paddingBottom: 5}}>
                <Box
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h5" color="error">
                        Produkt nebyl nalezen.
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate('/products')}
                        sx={{marginTop: 2}}
                    >
                        Zpět na seznam produktů
                    </Button>
                </Box>
            </Container>
        );
    }

    const { id, name, size, price, thick, roundedCorner, description } = product;

    return (
        <Container sx={{paddingTop: 5, paddingBottom: 10}}>

            <Helmet>
                <title>{`${name} - ${size.width} x ${size.height} mm - Luxusní metalické obrazy od PrintKing.cz`}</title>
                <link data-rh="true" rel="canonical" href={`https://www.printking.cz/product/${id}`}/>
                <meta
                    name="description"
                    content={description}
                />
                <meta
                    name="keywords"
                    content={`metalický obraz, vlastní metalický obraz, kovový obraz, hliníkové obrazy, ${name}, luxusní obrazy, moderní dekorace`}
                />
                <meta property="og:title" content={`${name} - Luxusní metalické obrazy od PrintKing.cz`}/>
                <meta
                    property="og:description"
                    content={description}
                />
                {/*<meta property="og:image" content={image} />*/}
                <meta property="og:url" content={`https://www.printking.cz/product/${id}`}/>
                <meta property="og:type" content="product"/>
                <meta property="product:price:amount" content={`${price}`}/>
                <meta property="product:price:currency" content="CZK"/>
                <meta property="product:availability" content="https://schema.org/InStock"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={`${name} - Luxusní metalické obrazy od PrintKing.cz`}/>
                <meta
                    name="twitter:description"
                    content={description}
                />
                {/*<meta name="twitter:image" content={image} />*/}

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": `${name} ${size.width} x ${size.height} mm`,
                        "description": `${description}`,
                        "brand": {
                            "@type": "Brand",
                            "name": "PrintKing.cz"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": `https://www.printking.cz/product/${id}`,
                            "priceCurrency": "CZK",
                            "price": price,
                            "availability": "https://schema.org/InStock"
                        },
                        "sku": id,
                        "productID": id,
                        "category": "Home & Garden > Decor > Artwork",
                        "dimensions": {
                            "@type": "SizeSpecification",
                            "width": `${size.width} mm`,
                            "height": `${size.height} mm`
                        },
                        // "image": image || "https://www.printking.cz/default-image.jpg",
                    })}
                </script>
            </Helmet>

            <Grid
                container
                spacing={4}
                p={5}
                sx={{
                    backgroundColor: '#FAFAFA',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* Obrázek produktu */}
                <Grid size={{xs: 12, md: 6}}>
                    <Box
                        component="img"
                        src={`/static/placeholder_text.png`}
                        alt={`Váš obrázek`}
                        sx={{
                            width: '100%',
                            borderRadius: '12px',
                            objectFit: 'cover',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                </Grid>

                {/* Informace o produktu */}
                <Grid size={{xs: 12, md: 6}}>
                    <Typography
                        variant="h3"
                        component="h1"
                        sx={{
                            fontSize: 36,
                            fontWeight: 'bold',
                            color: 'primary.main',
                        }}
                    >
                        {name}
                    </Typography>
                    {product.recommended && (
                        <Box
                            sx={{
                                display: 'inline-block',
                                backgroundColor: 'primary.main', // Barva pozadí labelu
                                color: 'secondary.main', // Barva textu
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderRadius: '4px', // Zaoblené rohy
                                padding: '2px 8px', // Vnitřní odsazení
                                marginBottom: 2, // Vzdálenost od ostatního obsahu
                            }}
                        >
                            Doporučujeme
                        </Box>
                    )}

                    {/*<Typography*/}
                    {/*    variant="body1"*/}
                    {/*    sx={{*/}
                    {/*        color: '#757575',*/}
                    {/*        marginBottom: 2,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Detailní informace o produktu.*/}
                    {/*</Typography>*/}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 2,
                        }}
                        pt={5}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                                marginRight: 1,
                            }}
                        >
                            Velikost:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#757575',
                            }}
                        >
                            {formatSizeCM(size)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 2,
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                                marginRight: 1,
                            }}
                        >
                            Tloušťka:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#757575',
                            }}
                        >
                            {product.thick} mm
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 2,
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                                marginRight: 1,
                            }}
                        >
                            Kulaté rohy:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#757575',
                            }}
                        >
                            {product.roundedCorner ? 'Ano' : 'Ne'}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 3,
                            paddingTop: 5,
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                color: 'primary.main',
                            }}
                        >
                            {price} Kč
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            title={"Navrhněte si luxusní kovový obraz z vlastní fotografie."}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem', // základní velikost textu tlačítka
                                padding: '0.8rem 1.5rem', // základní padding tlačítka
                                '@media (max-width:600px)': { // media query pro mobilní zařízení
                                    fontSize: '0.875rem', // zmenšení textu
                                    padding: '0.6rem 1rem', // zmenšení paddingu
                                },
                            }}
                            onClick={() => onClickCreate()}
                        >
                            Vytvořit návrh
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {product.description && product.description.length > 0 && (
                <Box
                    mt={5}
                    p={2}
                    component="section"
                    aria-labelledby="product-description-title"
                >
                    <Typography
                        id="product-description-title"
                        variant="h4"
                        component="h2"
                        sx={{ marginBottom: 2 }}
                    >
                        Popis produktu
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: 'text.secondary' }}
                    >
                        {description}
                    </Typography>
                </Box>
            ) }




            <Box sx={{visibility: create ? 'visible' : 'hidden', height: create ? 'auto' : 0}}>
                <ImageCropper selectedProduct={product} fileInputRef={fileInputRef}/>
            </Box>

            {/* Accordion sekce */}
            <Box sx={{marginTop: 5}}>
                {accordionTexts.map((item, index) => (
                    <Accordion
                        key={index}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'secondary.light', // nastavení barvy při hoveru
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {item.heading}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                variant="body1"
                            >
                                {item.description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>

            <Box sx={{ marginTop: 8}}>
                <MetalImageGallery />
            </Box>
        </Container>
    );
};

export default ProductDetail;
