import React from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Import Grid2
import ProductCard from './ProductCard';
import { formatSize, products } from '../../resources/sizesConstants';
import {Helmet} from "react-helmet-async";
import Typography from "../../onepirate/modules/components/Typography";
import Box from "@mui/material/Box";
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from "../../onepirate/modules/components/Button";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const ProductPage: React.FC = () => {
    return (
        <Container sx={{ paddingTop: 10, paddingBottom: 20 }}>
            <Helmet>
                <title>Produkty - Metalické obrazy od PrintKing.cz</title>
                <link data-rh="true" rel="canonical" href="https://www.printking.cz/products"/>
                <meta
                    name="description"
                    content="Objevte širokou nabídku metalických obrazů na míru. Luxusní design, dlouhá životnost a jedinečná kvalita. Vyberte si ideální metalický obraz pro váš interiér."
                />
                <meta
                    name="keywords"
                    content="metalický obraz, metalické obrazy, kovové obrazy, hliníkové obrazy, luxusní obrazy, metalické fotografie, dekorace interiéru, moderní obrazy, PrintKing.cz"
                />
                <meta property="og:title" content="Produkty - Metalické obrazy od PrintKing.cz"/>
                <meta
                    property="og:description"
                    content="Objevte širokou nabídku metalických obrazů na míru. Luxusní design, dlouhá životnost a jedinečná kvalita. Vyberte si ideální metalický obraz pro váš interiér."
                />
                <meta property="og:url" content="https://www.printking.cz/products"/>
                {/*<meta property="og:image" content="https://www.printking.cz/images/products-preview.jpg" />*/}
                <meta property="og:type" content="website"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Produkty - Kovové obrazy od PrintKing.cz"/>
                <meta
                    name="twitter:description"
                    content="Objevte širokou nabídku metalických obrazů na míru. Luxusní design, dlouhá životnost a jedinečná kvalita. Vyberte si ideální metalický obraz pro váš interiér."
                />
                {/*<meta name="twitter:image" content="https://www.printking.cz/images/products-preview.jpg" />*/}

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Produkty - Metalické obrazy od PrintKing.cz",
                        "url": "https://www.printking.cz/products",
                        "description":
                            "Objevte širokou nabídku metalických obrazů na míru. Luxusní design, dlouhá životnost a jedinečná kvalita. Vyberte si ideální metalický obraz pro váš interiér.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "PrintKing.cz",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "%PUBLIC_URL%/logo192.png"
                            }
                        },
                        "breadcrumb": {
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "Domů",
                                    "item": "https://www.printking.cz/"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "Produkty",
                                    "item": "https://www.printking.cz/products"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "name": "Výhody obrazu",
                                    "item": "https://www.printking.cz/metalicky-obraz"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 4,
                                    "name": "Kontakt",
                                    "item": "https://www.printking.cz/contact"
                                }
                            ]
                        }
                    })}
                </script>
            </Helmet>
            <Typography variant="h3" component="h1" gutterBottom align="center">
                Metalické obrazy
            </Typography>
            <Typography variant="h5" marked="center" align="center" sx={{ paddingTop: 2 }}  component="h2">
                {/*Proměňte své vzpomínky v jedinečné kovové umění!*/}
                Prohlédněte si naši nabídku metalický obrazů různých velikostí a tlouštěk, ideálních pro každou příležitost. Metalické obrazy vynikají luxusním zpracováním a jsou dostupné od menších formátů na stůl až po větší, které ozdobí váš interiér. Nabízíme také varianty s kulatými rohy pro moderní a elegantní vzhled.  Vyberte si ideální metalický obraz pro váš interiér.
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 7 }}>
                <Typography variant="h6" textAlign={"center"} sx={{fontWeight: 'bold', color: 'primary.main'}}>
                    Vyberte si ideální metalický obraz pro váš interiér.
                    <span className="OnePirateTypography-markedH2Center"/>
                </Typography>
            </Box>

            <Grid container spacing={2} justifyContent="flex-start" pt={3} pb={5}>
                {products.map((product) => (
                    <Grid
                        key={product.id}
                        title={product.title}
                        size={{xs: 12, sm: 6, md: 4}} // Nastavení velikostí pro jednotlivé breakpointy
                    >
                        <ProductCard
                            id={product.id}
                            name={product.name}
                            size={product.size}
                            description="TEST"
                            price={product.price}
                            product={product}
                            ribbon={product.recommended}
                            ribbonText={product.recommended ? "Doporučujeme" : ""}
                        />
                    </Grid>
                ))}
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'secondary.light',
                    padding: 2,
                    borderRadius: 2,
                    marginTop: 3,
                    color: 'text.primary',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                }}
            >
                <MailOutlineIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                Nenašli jste požadovanou velikost metalického obrazu? Kontaktujte nás a rádi vám připravíme nabídku na míru.
            </Box>
        </Container>
    );
};

export default ProductPage;
