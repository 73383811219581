import React from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Definice typu pro jednotlivé FAQ položky
interface FAQItem {
    question: string;
    answer: string;
}

// Data pro FAQ
const faqData: FAQItem[] = [
    {
        question: "Jak nahraji fotku do konfigurátoru?",
        answer:
            // "Fotku nahrajete kliknutím na tlačítko 'Vložit obrázek' v konfigurátoru. Následně si ji můžete oříznout. Mějte na paměti, že obrázek musí být v dobré kvalitě.",
            "Fotku můžete snadno nahrát kliknutím na tlačítko 'Vložit obrázek' přímo v konfigurátoru. Po nahrání si ji můžete přizpůsobit a oříznout podle svých představ. Ujistěte se, že váš obrázek má dostatečně vysokou kvalitu pro perfektní tisk.",
    },
    {
        question: "Jaké rozlišení fotky je ideální?",
        answer:
            // "Doporučujeme nahrát fotky v minimálním rozlišení 300 DPI, aby byla kvalita tisku co nejlepší.",
            "Pro dosažení co nejlepších výsledků je důležité, aby byl obrázek v dobré kvalitě. Například při přiblížení v mobilním telefonu by neměl být obraz kostičkovaný nebo rozmazaný.",
    },
    {
        question: "Jak dlouho trvá výroba?",
        answer:
            // "Výroba je obvykle do 24 hodin od objednávky. O stavu vaší objednávky vás budeme informovat e-mailem.",
            "Vaši objednávku obvykle vyrobíme do 24 hodin od jejího přijetí. Jakmile bude váš obraz připraven, obdržíte e-mail s podrobnostmi o odeslání.",
    },
    {
        question: "Jaký materiál používáte?",
        answer:
            // "Používáme kvalitní hliníkové plechy, které zajišťují odolnost a vynikající vzhled potištěného obrazu.",
        "Pro výrobu používáme vysoce kvalitní hliníkové plechy s odolnou povrchovou úpravou, která zajišťuje luxusní vzhled a dlouhou životnost potištěného obrazu.",
    },
    {
        question: "Mohu změnit objednávku po jejím odeslání?",
        answer:
            // "Pokud nás kontaktujete do 24 hodin od vytvoření objednávky, můžeme provést změny.",
            "Ano, pokud potřebujete provést změnu, kontaktujte nás co nejdříve po vytvoření objednávky. Čím rychleji nás oslovíte, tím větší je šance, že změnu zvládneme zpracovat.",
    },
];

const FAQ: React.FC = () => {
    const theme = useTheme();

    return (
        <Box sx={{ margin: "auto", paddingTop: 6 }}>
            {/* Ohraničený text */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.default, // Pozadí z theme
                }}
                pb={5}
            >
                <Box
                    sx={{
                        display: "inline-block",
                        border: `4px solid ${theme.palette.primary.main}`, // Ohraničení z theme
                        borderRadius: 0, // Žádné zaoblení rohů
                        padding: "16px 32px", // Vnitřní odsazení
                        textAlign: "center",
                        color: theme.palette.primary.contrastText, // Barva textu z theme
                    }}
                >
                    <Typography
                        variant="h4"
                        component="span"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        Otázky a odpovědi
                    </Typography>
                </Box>
            </Box>

            {/* Seznam FAQ */}
            {faqData.map((item, index) => (
                <Accordion key={index}
                           sx={{
                               '&:hover': {
                                   backgroundColor: 'secondary.light', // zde nastavte požadovanou barvu
                               },
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 'bold' }}>{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default FAQ;
