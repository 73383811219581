// sizes.ts

import {MountType, Product} from "../components/PlateSizeType";

const mountTypeSmallSheet: Array<MountType> = [
    {id: 1, name: "Dřevěný stojánek", price: 20, description: "Pro umístění na stůl.", title: "Dřevěný stojánek – elegantní podpora pro umístění obrazu na stůl."}
]

const mountType: Array<MountType> = [
    {id: 1, name: "Dřevěný stojánek", price: 20, description: "Pro umístění na stůl.", title: "Dřevěný stojánek – elegantní podpora pro umístění obrazu na stůl."},
    {id: 2, name: "\"Neviditelný\" rámeček", price: 0, description: "Pro umístění na stěnu.", title: "Neviditelný rámeček – čistý vzhled pro zavěšení na stěnu."}
]

const mountTypeBigSize: Array<MountType> = [
    {id: 2, name: "\"Neviditelný\" rámeček", price: 0, description: "Pro umístění na stěnu.", title: "Neviditelný rámeček – čistý vzhled pro zavěšení na stěnu."}
]

const title1: string = "Lehký kovový obraz z vlastní fotografie s kulatými rohy.";
const title2: string = "Robustní kovový obraz ideální pro luxusní portréty na zeď.";
const title3: string = "Doporučujeme: Prémiový kovový obraz pro moderní interiér.";
const title4: string = "Lehký fotoobraz z kovu, perfektní pro rodinné okamžiky.";
const title5: string = "Stylový kovový obraz na zeď, ideální pro přírodní motivy.";
const title6: string = "Doporučujeme: Luxusní kovový obraz s precizním zpracováním.";
const title7: string = "Klasický kovový obraz vhodný pro fotografie na stůl či zeď.";
const title8: string = "Elegantní kovový obraz z plechu, ideální pro velké fotky.";

const title9: string = "Moderní kovový obraz z vlastní fotografie, ideální pro váš interiér.";
const title10: string = "Precizní kovový obraz na zeď, perfektní pro uchování vzpomínek.";
const title11: string = "Prémiový kovový obraz s minimalistickým designem pro moderní domov.";
const title12: string = "Unikátní kovový obraz z hliníku, skvělý doplněk do každého prostoru.";


// const desc1: string = "Lehký kovový obraz s kulatými rohy, ideální pro uchování vašich oblíbených fotografií. Vytvořte si vlastní metalický obraz, který bude ozdobou každého interiéru.";
// const desc2: string = "Robustní kovový obraz z hliníku je perfektní volbou pro luxusní portréty na zeď. Přeneste své vzpomínky na vlastní metalický obraz s moderním designem.";
// const desc3: string = "Prémiový kovový obraz doporučujeme pro jedinečnou dekoraci do vašeho interiéru. Skvělé pro ty, kteří chtějí vytvořit vlastní metalický obraz s precizním zpracováním.";
// const desc4: string = "Tento lehký fotoobraz z kovu je ideální pro zachycení rodinných okamžiků. Vytvořte si vlastní metalický obraz, který uchová vzácné vzpomínky v dokonalé kvalitě.";
// const desc5: string = "Stylový kovový obraz s nádherným přírodním motivem. Ideální volba, jak si vytvořit vlastní metalický obraz a přenést krásu přírody na zeď.";
// const desc6: string = "Luxusní kovový obraz s kulatými rohy a precizním zpracováním. Vytvořte si vlastní metalický obraz, který dodá vašemu interiéru jedinečný nádech elegance.";
// const desc7: string = "Klasický kovový obraz, ideální pro menší fotografie na stůl nebo jako doplněk na zeď. Navrhněte si vlastní metalický obraz pro každodenní radost.";
// const desc8: string = "Elegantní kovový obraz z plechu, navržený pro větší fotografie. Vytvořte si vlastní metalický obraz, který se stane dominantou vašeho interiéru.";

const desc1 : string = "Lehký kovový obraz s kulatými rohy, ideální pro uchování vašich oblíbených fotografií. Vytvořte si vlastní metalický obraz, který bude ozdobou každého interiéru. Tento obraz je perfektní volbou pro ty, kteří hledají stylovou, ale zároveň odolnou dekoraci. Přeneste své vzpomínky na kov a zachovejte je v mimořádné kvalitě.";
const desc2 : string = "Robustní kovový obraz z hliníku je perfektní volbou pro luxusní portréty na zeď. Přeneste své vzpomínky na vlastní metalický obraz s moderním designem. Díky své odolnosti a kvalitnímu provedení je tento obraz ideální pro zdůraznění jedinečných momentů vašeho života. Dodejte svému domovu nádech elegance.";
const desc3 : string = "Prémiový kovový obraz doporučujeme pro jedinečnou dekoraci do vašeho interiéru. Skvělé pro ty, kteří chtějí vytvořit vlastní metalický obraz s precizním zpracováním. Tento obraz vyniká svou trvanlivostí a luxusním vzhledem, což z něj dělá ideální volbu pro moderní bydlení. Vyjádřete svůj styl jedinečným uměním na kovu.";
const desc4 : string = "Tento lehký fotoobraz z kovu je ideální pro zachycení rodinných okamžiků. Vytvořte si vlastní metalický obraz, který uchová vzácné vzpomínky v dokonalé kvalitě. Skvěle se hodí jako dárek pro vaše blízké nebo jako dekorace, která oživí váš domov. Vychutnejte si nadčasový design, který vydrží po generace.";
const desc5 : string = "Stylový kovový obraz. Ideální volba, jak si vytvořit vlastní metalický obraz, který obohatí váš interiér. Tento obraz přidá vašemu prostoru moderní a elegantní vzhled. Ať už preferujete minimalistický design nebo výrazné vzory, kovový obraz se přizpůsobí vašemu jedinečnému stylu. Díky své kvalitě a odolnosti je skvělým doplňkem pro každý domov.";
const desc6 : string = "Luxusní kovový obraz s kulatými rohy a precizním zpracováním. Vytvořte si vlastní metalický obraz, který dodá vašemu interiéru jedinečný nádech elegance. Tento prémiový obraz je perfektní volbou pro ty, kteří hledají spojení moderního designu a dlouhé životnosti. Dodejte svému prostoru sofistikovanost.";
const desc7 : string = "Klasický kovový obraz, ideální pro menší fotografie na stůl nebo jako doplněk na zeď. Navrhněte si vlastní metalický obraz pro každodenní radost. Díky kompaktní velikosti a kvalitnímu provedení je tento obraz vhodný pro jakýkoliv interiér. Udržte své vzpomínky blízko a elegantně zpracované.";
const desc8 : string = "Elegantní kovový obraz z plechu, navržený pro větší fotografie. Vytvořte si vlastní metalický obraz, který se stane dominantou vašeho interiéru. Tento obraz nabízí kombinaci robustnosti a estetického designu, díky čemuž je ideální volbou pro výrazné a jedinečné momenty. Zanechte trvalý dojem s uměním na kovu.";

const desc9 : string = "Moderní kovový obraz, který kombinuje stylový design a výjimečnou kvalitu. Vytvořte si vlastní metalický obraz na míru, který promění vaše oblíbené fotografie na kov v luxusní dekoraci. Ideální volba pro ty, kteří hledají originální dárek na zeď nebo chtějí vylepšit svůj interiér jedinečným uměním. Přidejte svému domovu moderní a nadčasový vzhled.";
const desc10 : string = "Precizně zpracovaný kovový obraz na zeď je ideální pro ty, kteří chtějí uchovat své vzpomínky v luxusním provedení. Vyberte si vlastní fotografie na kov a vytvořte si moderní obraz, který zaujme každého. Tento metalický obraz vyniká svou odolností a elegancí, což z něj dělá skvělý doplněk do každého prostoru. Přeneste své oblíbené momenty na kov a vytvořte nezapomenutelné umění.";
const desc11 : string = "Prémiový kovový obraz s minimalistickým designem, který dokonale zvýrazní vaše vzpomínky. Přeneste své oblíbené fotografie na kov a užijte si luxusní obrazy na zeď s dlouhou životností. Tento stylový metalický obraz je ideální pro moderní interiéry i jako originální dárek na zeď. Kombinace kvality, elegance a odolnosti z něj dělá perfektní volbu pro každou domácnost.";
const desc12 : string = "Unikátní kovový obraz z hliníku, který dodá vašemu interiéru osobitý charakter. Vytvořte si vlastní metalický obraz s dokonalými detaily a užijte si luxusní dekoraci na stěnu. Tento moderní obraz je perfektní volbou pro ty, kteří chtějí kombinovat umění a praktičnost. Přeneste své jedinečné vzpomínky na kov a vyjádřete svůj styl prostřednictvím kvalitního provedení.";




export const products: Array<Product> = [
    { id: 1, name: "Kovový obraz", size: {width: 152, height: 203}, thick: 0.75, roundedCorner: true,  price: 299, mountType: mountType, title: title1, description:desc1 },
    { id: 2, name: "Kovový obraz", size: {width: 152, height: 203}, thick: 1.0, roundedCorner: true,  price: 309, mountType: mountType, title: title2, description:desc2 },
    { id: 3, name: "Kovový obraz", size: {width: 152, height: 203}, thick: 1.15, roundedCorner: true,  price: 349, recommended: true, mountType: mountType, title: title3, description:desc3 },
    { id: 4, name: "Kovový obraz", size: {width: 178, height: 254}, thick: 0.75, roundedCorner: true,  price: 399, mountType: mountType, title: title4, description:desc4 },
    { id: 5, name: "Kovový obraz", size: {width: 178, height: 254}, thick: 1.0, roundedCorner: true,  price: 409, mountType: mountType, title: title5, description:desc5 },
    { id: 6, name: "Kovový obraz", size: {width: 178, height: 254}, thick: 1.15, roundedCorner: true,  price: 449, recommended: true, mountType: mountType, title: title6, description:desc6 },
    { id: 7, name: "Kovový obraz", size: {width: 200, height: 285}, thick: 1.0, roundedCorner: false,  price: 499, mountType: mountType, title: title7, description:desc7 },
    { id: 8, name: "Kovový obraz", size: {width: 200, height: 285}, thick: 1.0, roundedCorner: true, price: 499, mountType: mountType, title: title8, description:desc8 },
    { id: 9, name: "Metalický obraz", size: {width: 254, height: 508}, thick: 1.15, roundedCorner: true, price: 1090, mountType: mountTypeBigSize, title: title8, description:desc8 },
    { id: 10, name: "Metalický obraz", size: {width: 305, height: 457}, thick: 1.15, roundedCorner: true, price: 1190, mountType: mountTypeBigSize, title: title8, description:desc8 },
    { id: 11, name: "Metalický obraz", size: {width: 400, height: 600}, thick: 1.15, roundedCorner: true, price: 1690, mountType: mountTypeBigSize, title: title8, description:desc8 },
    { id: 12, name: "Metalický obraz", size: {width: 508, height: 762}, thick: 1.15, roundedCorner: true, price: 1990, mountType: mountTypeBigSize, title: title8, description:desc8 }
];



export const formatSize = (size: { width: number, height: number }) => `${size.width} x ${size.height} mm`;
export const formatSizeCM = (size: { width: number, height: number }) => {
    const widthInCM = Math.floor(size.width / 10); // Převod mm na cm a zaokrouhlení dolů
    const heightInCM = Math.floor(size.height / 10); // Převod mm na cm a zaokrouhlení dolů
    return `${widthInCM} x ${heightInCM} cm`;
};