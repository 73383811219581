import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'; // Přidání Container
import {useRecoilValue} from 'recoil';
import {cartState} from '../../../recoil/atoms';
import {useNavigate} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import InfoBar from "../../../components/Toast/InfoBar";
import {sendGtagEvent} from "../../../utils/google";

function AppAppBar() {
    const navigate = useNavigate();
    const cart = useRecoilValue(cartState);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detekce mobilního zařízení
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const menuItems = [
        {label: 'Produkty', path: '/products', title: "Metalické obrazy na míru – luxusní kovové obrazy na zeď."},
        {label: 'Výhody obrazu', path: '/metalicky-obraz', title: "Proč metalický obraz?"},
        {label: 'Kontakt', path: '/contact', title: "Kontaktní údaje Printking pro objednávky metalických obrazů."},
        // { label: 'Objednat', path: '#order' },
    ];

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigation = (path: string) => {
        window.scrollTo(0, 0); // Posun na začátek stránky
        navigate(path);
        handleMenuClose();
    };

    return (
        <div>
            <AppBar position="fixed" color="primary">
                <Container maxWidth="lg">
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            px: 2,
                        }}
                    >
                        {/* Hamburger menu vlevo pro mobilní verzi */}
                        {isMobile && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleMenuOpen}
                                sx={{
                                    mr: 2,
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                        )}

                        {/*Logo Print King ve středu */}
                        {/*<Typography*/}
                        {/*    variant="h6"*/}
                        {/*    sx={{*/}
                        {/*      position: 'absolute',*/}
                        {/*      left: '50%',*/}
                        {/*      transform: 'translateX(-50%)',*/}
                        {/*      fontSize: 24,*/}
                        {/*      cursor: 'pointer',*/}
                        {/*      color: 'secondary',*/}
                        {/*    }}*/}
                        {/*    title={"Úvodní strana Printking – Metalické obrazy z vašich fotografií"}*/}
                        {/*    onClick={() =>  handleNavigation("/")}*/}
                        {/*>*/}
                        {/*    <img*/}
                        {/*        src="/static/logo.png" // Upravte cestu k obrázku loga*/}
                        {/*        alt="Print King Logo"*/}
                        {/*        style={{ height: isMobile ? 40 : 55, marginTop: 8 }} // Můžete upravit výšku dle potřeby*/}
                        {/*    />*/}
                        {/*</Typography>*/}

                        {/*Logo vlevo a u mobilu na stredu*/}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: isMobile ? 'center' : 'flex-start', // Na mobilu na střed, na desktopu vlevo
                                position: 'absolute',
                                left: isMobile ? '50%' : 16, // Na mobilu horizontální centrování
                                transform: isMobile ? 'translateX(-50%)' : 'none', // Na mobilu zarovnání na střed
                                cursor: 'pointer',
                            }}
                            onClick={() => handleNavigation("/")}
                            title="Úvodní strana Printking – Metalické obrazy z vašich fotografií"
                        >
                            <img
                                src="/static/logo.png" // Upravte cestu k obrázku loga
                                alt="Print King Logo"
                                style={{height: isMobile ? 40 : 45}} // Výška loga dle zařízení
                            />
                        </Box>


                        {/* Menu a košík na pravé straně */}
                        <Box
                            sx={{
                                ml: 'auto', // Zarovnání doprava
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                            }}
                        >
                            {!isMobile &&
                                menuItems.map((item) => (
                                    <Link
                                        component="button"
                                        key={item.label}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            e.preventDefault();
                                            handleNavigation(item.path); // Navigace bez href
                                        }}
                                        title={item.title}
                                        sx={{
                                            fontSize: "1.0rem",
                                            fontWeight: 700,
                                            fontFamily: "'Roboto Condensed', sans-serif",
                                            textTransform: 'uppercase',
                                            color: 'secondary.main',
                                            textAlign: 'left', // Volitelný styl
                                            '&:hover': {
                                                color: 'secondary.light', // Změna barvy při hoveru
                                            },
                                        }}
                                    >
                                        {item.label}
                                    </Link>

                                ))}

                            {/* Košík */}
                            <Link
                                variant="h6"
                                underline="none"
                                href="/cart"
                                onClick={(e) => {
                                    e.preventDefault();
                                    sendGtagEvent('event', 'conversion', {
                                        'send_to': ['AW-16790889443/mQoTCJnXm_UZEOPHwsY-', 'AW-16790889443'],
                                    });
                                    handleNavigation('/cart');
                                }}
                                title={"Košík – Vaše objednávka metalických obrazů z vlastní fotografie."}
                                aria-label={"Košík – Vaše objednávka metalických obrazů z vlastní fotografie."}
                                sx={{
                                    color: 'secondary.main',
                                    display: 'flex',
                                    alignItems: 'center',
                                    '&:hover': {
                                        color: 'secondary.light', // Změna barvy celého odkazu při hoveru
                                        '& .cart-length': {
                                            color: 'secondary.light', // Změna barvy pouze pro cart.length
                                        },
                                    },
                                }}
                            >
                                <ShoppingCartIcon sx={{mr: 1}}/>
                                <Typography
                                    variant="body1"
                                    className="cart-length" // Třída pro selektor
                                    sx={{
                                        color: 'secondary.main', // Výchozí barva textu
                                    }}
                                >
                                    {cart.length}
                                </Typography>
                            </Link>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Mobilní menu */}
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    display: { xs: 'block', md: 'none' }, // Viditelné pouze na mobilu
                    marginTop: 1,
                }}
                marginThreshold={0}
                slotProps={{
                    paper: {
                        sx: {
                            color: 'primary.main',
                            width: '100%',
                            maxWidth: '100%',
                            left: '0px',
                            right: '0px',
                            backgroundColor: 'primary.main',
                        },
                    },
                }}
            >
                {menuItems.map((item) => (
                    <MenuItem
                        key={item.label}
                        onClick={() => {
                            handleNavigation(item.path);
                            handleMenuClose(); // Zavření menu po kliknutí
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            transition: 'background-color 0.3s ease', // Plynulá animace
                            '&:hover': {
                                backgroundColor: 'primary.dark', // Barva pozadí při hover
                                color: 'white', // Barva textu při hover
                            },
                            '&:active': {
                                backgroundColor: 'secondary.main', // Barva při kliknutí
                                color: 'white', // Text při kliknutí
                            },
                        }}
                    >
                        <Typography
                            color="secondary.main"
                            align="center"
                            sx={{
                                fontSize: "0.725rem",
                                fontWeight: 600,
                                fontFamily: "'Roboto Condensed', sans-serif",
                                textTransform: 'uppercase',
                            }}
                        >
                            {item.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>



            <Toolbar/>
            {/*<InfoBar*/}
            {/*    message='🎄 Vánoční dárek pro vás! 🎄 Do konce prosince nabízíme dopravu "Zásilkovna - Výdejní místo" i platbu na dobírku zdarma. 🎁'*/}
            {/*    textColor="primary.main"*/}
            {/*/>*/}
            {/*<InfoBar*/}
            {/*    message='Poslední expedice objednávek proběhne 19. prosince. Objednávky vytvořené po tomto datu budou odbaveny 30. prosince.'*/}
            {/*    textColor="primary.main"*/}
            {/*/>*/}
        </div>
    );
}

export default AppAppBar;
