import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import HdrEnhancedSelectIcon from '@mui/icons-material/HdrEnhancedSelect';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DiamondIcon from '@mui/icons-material/Diamond';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

{/*Od jemných detailů po syté barvy – naše technologie zajišťuje dokonalou reprodukci každého snímku na odolném hliníkovém plechu.*/}
{/*Vytvořte si své vlastní mistrovské dílo! Nahrajte svou fotografii, upravte rozměry podle svých představ v našem jednoduchém konfigurátoru.*/}
{/*Vaše kovové obrazy doručíme přímo k vám domů v bezpečném balení, které zajistí, že váš výtvor dorazí v perfektním stavu.*/}


function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 7, mb: 10, display: 'flex', position: 'relative' }}>
        <Box
            component="img"
            src="/static/productCurvyLines.png"
            alt="curvy lines"
            sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box><DiamondIcon sx={{ fontSize: 55, color: 'secondary.main' }} /></Box>
              <Typography variant="h6" sx={{ my: 5, textAlign: "center", fontWeight: 'bold' }}>
                Prémiová kvalita tisku
              </Typography>
              <Typography variant="h5" sx={{ textAlign: "center", fontStyle: 'italic' }}>
                Objevte dokonalost zpracování. Naše technologie přináší neuvěřitelnou ostrost detailů a hluboké, živé barvy na luxusním hliníkovém plechu. Vaše fotografie se promění v umělecké dílo, které upoutá každý pohled.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box><PhotoFilterIcon sx={{ fontSize: 55, color: 'secondary.main' }} /></Box>
              <Typography variant="h6" sx={{ my: 5, textAlign: "center", fontWeight: 'bold' }}>
                Personalizovaný design
              </Typography>
              <Typography variant="h5" sx={{ textAlign: "center", fontStyle: 'italic' }}>
                Proměňte své vzpomínky v nadčasové kovové obrazy. Nahráním své oblíbené fotografie a úpravou v našem sofistikovaném konfigurátoru vytvoříte jedinečný kousek, který odráží váš styl a vkus.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <LocalShippingIcon sx={{ fontSize: 55, color: 'secondary.main' }} />
              <Typography variant="h6" sx={{ my: 5, textAlign: "center", fontWeight: 'bold' }}>
                Bezpečné doručení
              </Typography>
              <Typography variant="h5" sx={{ textAlign: "center", fontStyle: 'italic' }}>
                Každý obraz vyrábíme s láskou a péčí, jako bychom jej připravovali pro své nejbližší. Doručujeme v exkluzivním balení, které zajistí, že váš kovový skvost dorazí v dokonalém stavu – připravený ozdobit váš domov.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
