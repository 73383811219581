import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Helmet>
                <title>PrintKing.cz - Luxusní kovové obrazy</title>
                <link data-rh="true" href="https://www.printking.cz" rel="canonical"/>
                <meta name="description"
                      content="Proměňte své vzácné vzpomínky v jedinečný metalický obraz. Tisk na kov dodá vašim fotografiím luxusní design a trvanlivost. Obrazy na kovu jsou moderní dekorací pro váš interiér."/>
                <meta name="keywords" content="tisk na hliníkové plechy, kovové obrazy, luxusní obrazy, metalické fotografie, vlastní design, kvalitní tisk"/>
                <meta property="og:title" content="PrintKing.cz - Kovové obrazy na míru"/>
            </Helmet>
            <App/>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
