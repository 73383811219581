import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box } from '@mui/material';
import Container from "@mui/material/Container";
import CartItem from "./CartItems";
import CheckoutOptions from "./CheckoutOptions";
import CustomerForm from "./CustomerForm";
import OrderSummary from "./OrderSummary";
import { useRecoilState } from "recoil";
import { cartState, checkoutState } from "../recoil/atoms";
import { useCreateOrder } from '../api/order_api';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import Snackbar from "../onepirate/modules/components/Snackbar";

const steps = ['Nákupní košík', 'Doprava & Platba', 'Informace o Vás', 'Rekapitulace'];

const defaultCheckoutState = {
    cartItems: [],
    shippingPaymentInfo: {
        shippingMethod: null,
        paymentMethod: null
    },
    customerInfo: {
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: ''
        },
        email: '',
        phone: ''
    },
    message: '',
    couponCode: null,
};

const CheckoutProcess: React.FC = () => {
    const [cart, setCart] = useRecoilState(cartState);
    const [checkoutData, setCheckoutData] = useRecoilState(checkoutState);
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detekce mobilního zařízení
    console.log("cart", cart);
    console.log("checkoutData", checkoutData);
    // snackbar
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };


    const mutation = useCreateOrder();

    const handleNext = () => {
        if (activeStep === 0) {
            setCheckoutData({...checkoutData, cartItems: cart});
        }
        window.scrollTo(0, 0);
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        window.scrollTo(0, 0);
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleSendOrder = () => {
        console.log("handleSendOrder", checkoutData);
        mutation.mutate(checkoutData, {
            onSuccess: () => {
                window.scrollTo(0, 0);
                setCheckoutData(defaultCheckoutState); // Reset checkout state do výchozího stavu
                setCart([]); // Reset košíku
                setActiveStep((prevStep) => prevStep + 1); // Přechod na poděkování
            },
            onError: (error: Error) => {
                setOpen(true);
                console.error("Chyba při odesílání objednávky:", error);
            }
        });
    };

    const isNextButtonDisabled = () => {
        if (cart.length < 1) {
            return true;
        }

        // Doprava a platba
        if (activeStep === 1) {
            const shippingMethod = checkoutData.shippingPaymentInfo.shippingMethod;
            const paymentMethod = checkoutData.shippingPaymentInfo.paymentMethod;

            // Pokud shippingMethod.id je 4, zkontrolovat také shippingMethod.place
            if (shippingMethod && shippingMethod.id === 4) {
                return !shippingMethod.place || !paymentMethod;
            }

            // Pro ostatní případy kontrolovat pouze přítomnost shippingMethod a paymentMethod
            return !shippingMethod || !paymentMethod;
        }
        // Informace o vas
        if (activeStep === 2) {
            const { firstName, lastName, address, email, phone } = checkoutData.customerInfo;
            return !firstName || !lastName || !address.street || !address.city || !address.state || !address.zipCode || !email || !phone;
        }
        return false;
    };

    return (
        <Container
            component="section"
            sx={{ display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', my: 4 }}
        >
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        width: "100%",
                        padding: isMobile ? 1 : 4,
                        display: isMobile ? "flex" : "block",
                        flexDirection: "column", // Pro vertikální Stepper na mobilu
                        alignItems: "center", // Zarovnání na střed
                        justifyContent: isMobile ? "center" : "flex-start", // Vertikální zarovnání na střed v mobilní verzi
                    }}
                >
                    <Stepper
                        activeStep={activeStep}
                        orientation={isMobile ? "vertical" : "horizontal"}
                    >
                        {steps.map((label, index) => (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                <Box mt={8}>
                    {activeStep === steps.length ? (
                        <Typography align={"center"}>Děkujeme za Vaši objednávku.</Typography>
                    ) : (
                        <>
                            {activeStep === 0 && <CartItem />}
                            {activeStep === 1 && <CheckoutOptions />}
                            {activeStep === 2 && <CustomerForm />}
                            {activeStep === 3 && <OrderSummary />}
                            <Box mt={8} display="flex" justifyContent="flex-end" flexDirection="column" alignItems="flex-end">
                                {activeStep === steps.length - 1 && (
                                    <Typography variant="body2" color="textSecondary">
                                        Odesláním objednávky souhlasíte s obchodními podmínkami a podmínkami ochrany osobních údajů
                                    </Typography>
                                )}
                                <Box mt={2} mb={6}>
                                    <Button disabled={activeStep === 0} onClick={handleBack}>
                                        Zpět
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={isNextButtonDisabled()}
                                        onClick={activeStep === steps.length - 1 ? handleSendOrder : handleNext}
                                        sx={{ ml: 1 }}
                                    >
                                        {activeStep === steps.length - 1 ? 'Závazně objednat' : 'Pokračovat'}
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="Objednávku se nepodařilo odeslat. Kontaktujte nás prosím, rádi vám pomůžeme."
            />
        </Container>
    );
};

export default CheckoutProcess;