import React from 'react';
import {Box, Link, Typography} from '@mui/material';

interface KontaktProps {
    nazevFirmy: string;
    ico: string;
    adresa: string;
    telefon: string;
    email: string;
}

const Kontakt: React.FC<KontaktProps> = ({nazevFirmy, ico, adresa, telefon, email}) => {
    return (
        <Box
            sx={{
                padding: '20px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                maxWidth: '400px',
                margin: '20px auto',
            }}
        >
            <Typography variant={"h6"} color={"#fff"} component={"h3"}>{nazevFirmy}</Typography>
            <Typography
                color={"#fff"}
                sx={{
                    paddingTop: 1,
                }}
            >
                IČO: {ico}
            </Typography>
            <Typography
                color={"#fff"}
                sx={{
                    paddingTop: 1,
                }}
            >
                {adresa}
            </Typography>
            <Typography
                color={"#fff"}
                sx={{
                    paddingTop: 1,
                }}
            >
                <Link
                    href={`tel:${telefon.replace(/\s+/g, '')}`}
                    underline="hover"
                    color="inherit"
                >
                    {telefon}
                </Link>
            </Typography>
            <Typography
                color={"#fff"}
                sx={{
                    paddingTop: 1,
                }}
            >
                <Link
                    href={`mailto:${email.replace(/\s+/g, '')}`}
                    underline="hover"
                    color="inherit"
                >
                    {email}
                </Link>
            </Typography>
        </Box>


    );
};

export default Kontakt;